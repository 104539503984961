import React, { useState,useEffect } from 'react';
import {
    Container,
    TextField,
    Typography,
    Button,
    Grid,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Divider,
    Radio,
    RadioGroup,
    Tabs,
    Tab,
    Paper,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import useLanguageData from '../../data';

const writeToUsSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    phone: Yup.string().min(10, 'Phone Number is invalid').required('Phone Number is required'),
});

const initialValues = {
    name: '',
    email: '',
    phone: '',
    country: '',
};
const WriteToUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const {
        countries,
        customerTypes,
        issues,
        machineTypes,
        fields,
        label,
        buttons,
        tabs,
    } = useLanguageData().writeToUs;

    const [software, setSoftware] = useState(false);

    return (
        <Container className="padding-top-20">
            <Typography variant='h4' align='center'>
                {label}
            </Typography>
            <br />
            <Paper>
                <Tabs value={software} onChange={() => setSoftware((s) => !s)} variant='fullWidth'>
                    <Tab className='tab-header' label={tabs[0]} disabled={!software} />
                    <Tab className='tab-header' label={tabs[1]} disabled={software} />
                </Tabs>
            </Paper>
            <Formik
                initialValues={initialValues}
                validationSchema={writeToUsSchema}
                onSubmit={(values) => {
                    console.log(values);
                }}>
                {(formik) =>
                    (({ errors, touched }) => (
                        <Form>
                            <Grid container>
                                <Grid item container>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name='name'
                                            variant='outlined'
                                            label={fields.name.label}
                                            fullWidth
                                            {...formik.getFieldProps('name')}
                                            margin='normal'
                                            color={errors.name && touched.name ? 'secondary' : 'primary'}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {errors.name && touched.name ? <span>{errors.name}</span> : null}
                                    </Grid>
                                </Grid>
                                <Grid item container>
                                    <Grid item sm={4} xs={12}>
                                        <TextField
                                            name='email'
                                            variant='outlined'
                                            label={fields.email.label}
                                            fullWidth
                                            {...formik.getFieldProps('email')}
                                            margin='normal'
                                            color={errors.email && touched.email ? 'secondary' : 'primary'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        {errors.email && touched.email ? <span>{errors.email}</span> : null}
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            name='phone'
                                            variant='outlined'
                                            label={fields.phone.label}
                                            fullWidth
                                            {...formik.getFieldProps('phone')}
                                            margin='normal'
                                            color={errors.phone && touched.phone ? 'secondary' : 'primary'}
                                        />
                                    </Grid>
                                    <Grid item sm={2} xs={12}>
                                        {errors.phone && touched.phone ? <span>{errors.phone}</span> : null}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        name='country'
                                        select
                                        label={fields.country}
                                        variant='outlined'
                                        required
                                        fullWidth
                                        margin='normal'
                                        {...formik.getFieldProps('country')}>
                                        {countries.map((value) => (
                                            <MenuItem key={value} value={value}>
                                                {value}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item container>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            name='nameOfBusiness'
                                            variant='outlined'
                                            label={fields.nameOfBusiness.label}
                                            fullWidth
                                            {...formik.getFieldProps('nameOfBusiness')}
                                            margin='normal'
                                            color={
                                                errors.nameOfBusiness && touched.nameOfBusiness
                                                    ? 'secondary'
                                                    : 'primary'
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        {errors.nameOfBusiness && touched.nameOfBusiness ? (
                                            <span>{errors.nameOfBusiness}</span>
                                        ) : null}
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <TextField
                                            name='companyWebsite'
                                            variant='outlined'
                                            label={fields.companyWebsite}
                                            fullWidth
                                            {...formik.getFieldProps('companyWebsite')}
                                            margin='normal'
                                            color={
                                                errors.companyWebsite && touched.companyWebsite
                                                    ? 'secondary'
                                                    : 'primary'
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        {errors.companyWebsite && touched.companyWebsite ? (
                                            <span>{errors.companyWebsite}</span>
                                        ) : null}
                                    </Grid>
                                </Grid>
                                {!software ? (
                                    <>
                                        <Grid item sm={4} xs={12}>
                                            <TextField
                                                name='customerType'
                                                select
                                                label={fields.customerType}
                                                variant='outlined'
                                                fullWidth
                                                margin='normal'
                                                {...formik.getFieldProps('customerType')}>
                                                {customerTypes.map((value) => (
                                                    <MenuItem key={value} value={value}>
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            {errors.customerType && touched.customerType ? (
                                                <span>{errors.customerType}</span>
                                            ) : null}
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                name='numOfUsers'
                                                variant='outlined'
                                                label={fields.numberOfUsers}
                                                fullWidth
                                                margin='normal'
                                                {...formik.getFieldProps('numOfUsers')}
                                                type='number'
                                                color={
                                                    errors.numOfUsers && touched.numOfUsers ? 'secondary' : 'primary'
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            {errors.numOfUsers && touched.numOfUsers ? (
                                                <span>{errors.numOfUsers}</span>
                                            ) : null}
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Divider />
                                                <Typography variant='h5'>{fields.machinaryType}</Typography>
                                            </Grid>
                                            {machineTypes.map((machine, i) => (
                                                <Grid item xs={6} sm={3} md={2} key={i}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name={'machine.' + machine.id}
                                                                {...formik.getFieldProps('machine.' + machine.id)}
                                                            />
                                                        }
                                                        label={machine.value}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                name='machine.others'
                                                variant='outlined'
                                                label={fields.others}
                                                fullWidth
                                                margin='normal'
                                                {...formik.getFieldProps('machine.others')}
                                                color={
                                                    errors.numOfUsers && touched.numOfUsers ? 'secondary' : 'primary'
                                                }
                                            />
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Divider />
                                                <Typography variant='h5'>{fields.isServiceOutsourced}</Typography>
                                            </Grid>
                                            <RadioGroup
                                                aria-label='outsourceInd'
                                                name='outsourceInd'
                                                {...formik.getFieldProps('outsourceInd')}>
                                                <FormControlLabel
                                                    value='yes'
                                                    control={<Radio />}
                                                    label={fields.serviceOutsourceAns[0]}
                                                />
                                                <FormControlLabel
                                                    value='no'
                                                    control={<Radio />}
                                                    label={fields.serviceOutsourceAns[1]}
                                                />
                                            </RadioGroup>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Divider />
                                                <Typography variant='h5'>{fields.isSoftwareUsedNow}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    name='softwares'
                                                    variant='outlined'
                                                    label={fields.otherSoftwares}
                                                    fullWidth
                                                    margin='normal'
                                                    {...formik.getFieldProps('softwares')}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant='h5'>{fields.issuesIdentified}</Typography>
                                            </Grid>
                                            {issues.map((issue, i) => (
                                                <Grid item xs={6} sm={4} md={2} key={i}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name={'issue.' + issue.id}
                                                                {...formik.getFieldProps('issue.' + issue.id)}
                                                            />
                                                        }
                                                        label={issue.value}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>

                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Divider />
                                                <Typography variant='h5'>{fields.years}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    name='businessDuration'
                                                    variant='outlined'
                                                    label={fields.experience}
                                                    fullWidth
                                                    margin='normal'
                                                    {...formik.getFieldProps('businessDuration')}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={12}>
                                            <br />
                                            <Typography variant='h5'>{fields.businessDescription}</Typography>
                                            <TextField
                                                name='businessDescription'
                                                variant='outlined'
                                                fullWidth
                                                multiline
                                                rows={5}
                                                {...formik.getFieldProps('businessDescription')}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <br />
                                            <Typography variant='h5'>{fields.startUp}</Typography>
                                            <TextField
                                                name='startUpBusinessDescription'
                                                variant='outlined'
                                                fullWidth
                                                multiline
                                                rows={5}
                                                {...formik.getFieldProps('startUpBusinessDescription')}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <br />
                            <Grid container justify='center'>
                                <Button type='submit' variant='contained' color='secondary' size='large'>
                                    {buttons.submit}
                                </Button>
                            </Grid>
                        </Form>
                    ))(formik)
                }
            </Formik>
        </Container>
    );
};

export default WriteToUs;
