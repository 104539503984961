import React from 'react';
import { Box, Card } from '@material-ui/core';
import useLanguageData from '../../data';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EmailIcon from '@material-ui/icons/Email';

const Footer = () => {
  const { content } = useLanguageData().footer;
  let { address, email } = useLanguageData().contactUs.details[0];

  address = address.split('\n').join(' ');

  const year = new Date().getFullYear();

  return (
    <Card color="primary" id="footer" elevation={24} variant="outlined">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        padding="10px"
        marginTop="20px"
      >
        <div>
          <p> {content}</p>
          <p>&#169; {year} SPIL.</p>
        </div>
        <Box minWidth="120px">
          <FacebookIcon
            className="logo"
            onClick={() =>
              window.open(
                'https://www.facebook.com/SPIL-Glass-Software-112131755538921/',
                '_blank'
              )
            }
            fontSize="large"
          />
          <InstagramIcon
            className="logo"
            onClick={() =>
              window.open(
                'https://instagram.com/spilglasssoftware?igshid=1oep8jp4a4nz0',
                '_blank'
              )
            }
            fontSize="large"
          />
          <LinkedInIcon
            className="logo"
            onClick={() =>
              window.open(
                'https://www.linkedin.com/company/spil-software-solutions',
                '_blank'
              )
            }
            fontSize="large"
          />
        </Box>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            alignSelf="center"
          >
            <EmailIcon /> {email}
          </Box>
          <p>{address}</p>
        </Box>
      </Box>
    </Card>
  );
};

export default Footer;
