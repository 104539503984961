import { Box, Typography, Grid, Button } from '@material-ui/core';
import React from 'react';
import MediaCard from '../../components/card/mediacard';
import { NavLink } from 'react-router-dom';
import useLanguageData from '../../data';
import pdf from '../../resources/pdf/brochure.pdf';
import { getImageSrc } from '../../util/fileLocationUtil';
import './index.css';

const Products = () => {
    const { productList, description, label } = useLanguageData().products;

    return (
        <Box id='products'>
            <Typography className='title' align='center' variant='h4'>
                <b>{label}</b>
            </Typography>
            <p className="paraDiv">{description}</p>
            <br />
            <Grid container justify='center' spacing={4}>
                {productList.map((props) => (
                    <Grid item key={props.id} xs={12} sm={6} md={4}>
                        <NavLink className='no-link' to={{ pathname: '/products/' + props.id }}>
                            <MediaCard {...props} link={getImageSrc(props.link)} />
                        </NavLink>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Box display='flex' justifyContent='center' fontSize='40px'>
                        <Button
                            variant='contained'
                            onClick={() => window.open(pdf, '_blank')}
                            color='primary'
                            disableRipple
                            disableFocusRipple
                            size='large'>
                            Download Product Brochure
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Products;
