import { useState, useEffect } from 'react';
import {
    AppBar,
    Button,
    Menu,
    MenuItem,
    Toolbar,
    Hidden,
    Drawer,
    List,
    ListItem,
    Link,
    withStyles,
    SvgIcon,
} from '@material-ui/core';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import blueIcon from '../../resources/icon_white.png';
import { DE, FR, US } from 'country-flag-icons/react/3x2';
import { setLanguage } from '../../store/language';
import { LANGUAGES } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Menu as MenuIcon, ArrowBack } from '@material-ui/icons';
import useLanguageData from '../../data';
import './appbar.css';
import { withRouter } from 'react-router-dom';

const styles = () => ({
    navButton: {
        fontWeight: 700,
        fontSize: 'medium',
    },
});
const NavBar = ({ location, history, classes }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [isTransparent, setTransparent] = useState(true);

    const dispatch = useDispatch();
    const { language } = useSelector((store) => store.language);

    const { currentSection } = useSelector((state) => state.scroll);

    const { sections, sectionNames, loginBtnTxt } = useLanguageData().navbar;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLanguage = (lang) => {
        dispatch(setLanguage(lang));
        handleClose();
    };

    const toggleDrawer = (open) => (event) => {
        isHomePage && setDrawerOpen(open);
    };

    const isHomePage = location.pathname === '/';
    useEffect(() => {
        const scrollListener = () => {
            const currentHeight = window.scrollY;
            const navBarHeightLimit = 150;
            if (navBarHeightLimit <= currentHeight) {
                setTransparent(false);
            } else {
                setTransparent(true);
            }
        };
        window.addEventListener('scroll', scrollListener);
        return () => {
            window.removeEventListener('scroll', scrollListener);
        };
    }, []);

    const scrollToSection = (sectionId) => {
        const navBarHeight = isTransparent ? 100 : 60;
        const selectedElementTop = document.getElementById(sectionId).offsetTop;
        console.log('Scrolling');
        window.scrollTo({
            top: selectedElementTop - navBarHeight,
            behavior: 'smooth',
        });
    };

    const goBack = () => {
        history.goBack();
    };

    const gotoHome = () => {
        if (isHomePage) {
            scrollToSection('home');
        } else {
            history.push('/');
        }
    };
    return (
        <AppBar
            id='navbar'
            position={isTransparent && isHomePage ? 'fixed' : 'sticky'}
            color='primary'
            className={isTransparent && isHomePage ? 'navbar-tall-transparent' : ''}>
            <Toolbar variant='regular'>
                {isHomePage ? (
                    <Hidden mdUp>
                        <Button onClick={toggleDrawer(true)}>
                            <MenuIcon id='menu-icon' />
                        </Button>
                        <Drawer anchor='left' open={isDrawerOpen} onClose={toggleDrawer(false)}>
                            <List>
                                {sections
                                    .filter((_, i) => i !== 0)
                                    .map((section, index) => (
                                        <ListItem key={section}>
                                            <Button
                                                onClick={() => {
                                                    scrollToSection(section);
                                                    toggleDrawer(false);
                                                }}>
                                                {sectionNames[index + 1]}
                                            </Button>
                                        </ListItem>
                                    ))}
                            </List>
                        </Drawer>
                    </Hidden>
                ) : (
                    <Hidden>
                        <Button
                            onClick={() => {
                                toggleDrawer(true);
                                goBack();
                            }}>
                            <ArrowBack id='back-icon' />
                        </Button>
                    </Hidden>
                )}

                <Link onClick={gotoHome} className='logo'>
                    <img
                        src={blueIcon}
                        className={isTransparent && isHomePage ? 'image-tall' : 'image-short'}
                        alt='SPIL'
                    />
                </Link>
                {isHomePage && (
                    <Hidden smDown>
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                            {sections
                                .filter((_, i) => i !== 0)
                                .map((section, index) => (
                                    <Button
                                        key={index}
                                        className={classes.navButton}
                                        onClick={() => scrollToSection(section)}
                                        disabled={currentSection === section}>
                                        {sectionNames[index + 1]}
                                    </Button>
                                ))}
                        </div>
                    </Hidden>
                )}

                <Button style={{ marginLeft: 'auto' }} aria-controls='menu' aria-haspopup='true' onClick={handleClick}>
                    {language === LANGUAGES[1] ? (
                        <SvgIcon fontSize="large">
                            <DE />
                        </SvgIcon>
                    ) : language === LANGUAGES[2] ? (
                        <SvgIcon fontSize="large">
                            <FR />
                        </SvgIcon>
                    ) : (
                        <SvgIcon fontSize="large">
                            <US />
                        </SvgIcon>
                    )}
                    <ExpandMoreIcon />
                </Button>
                <Button onClick={() => window.open("https://login.xero.com/identity/user/login","_blank")}>
                    <DeveloperBoardIcon color='primary' fontSize='large' />&nbsp;
                    <span className={classes.navButton}>{loginBtnTxt}</span>
                </Button>
                <Menu id='menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem onClick={() => changeLanguage(LANGUAGES[0])}>
                        <SvgIcon fontSize="large">
                            <US />
                        </SvgIcon>
                        &nbsp;&nbsp;&nbsp;{LANGUAGES[0]}
                    </MenuItem>
                    <MenuItem onClick={() => changeLanguage(LANGUAGES[1])}>
                        <SvgIcon fontSize="large">
                            <DE />
                        </SvgIcon>
                        &nbsp;&nbsp;&nbsp;{LANGUAGES[1]}
                    </MenuItem>
                    <MenuItem onClick={() => changeLanguage(LANGUAGES[2])}>
                        <SvgIcon fontSize="large">
                            <FR />
                        </SvgIcon>
                        &nbsp;&nbsp;&nbsp;{LANGUAGES[2]}
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};
export default withRouter(withStyles(styles)(NavBar));
