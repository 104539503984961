import { Container, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import SimpleCard from '../../components/card/simpleCard';
import useLanguageData from '../../data';

const ContactDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { details, title, pageHeading } = useLanguageData().contactUs;
  return (
    <Container>
      <Grid container spacing={6} justify="center" alignContent="center">
        <Grid item xs={12}>
          <Typography
            className="title"
            align="center"
            variant="h4"
            style={{ marginTop: '20px' }}
          >
            <b>{title}</b>
          </Typography>
          <Typography align="center" variant="h6">
            {pageHeading}
          </Typography>
        </Grid>
        {details.map((detail, i) => (
          <Grid item xs={12} sm={6} md={4} style={{ height: '400px' }}>
            <SimpleCard key={i} {...detail} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ContactDetail;
