import React, { useEffect } from 'react';
import { Container, Typography, Box } from '@material-ui/core';
import useLanguageData from '../../../data';
import ContactUs from '../../contactUs';
import { getImageSrc } from '../../../util/fileLocationUtil';

const Opti = () => {
  const { content, name, link } = useLanguageData().products.productList[1];

  const src = getImageSrc(link);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="padding-top-20">
      <Typography variant="h4" align="center">
        {name}
      </Typography>
      <Box display="flex" justifyContent="center">
        <img src={src} alt={name} className="product-image" />
      </Box>
      <div
        className="paraDiv"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
      <ContactUs />
    </Container>
  );
};

export default Opti;
