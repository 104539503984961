import language from './language'
import scroll from './scroll'
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from '@reduxjs/toolkit'
const reducer = combineReducers({
    language, scroll
});
const store = configureStore({
  reducer,
})
export default store;