import { useEffect, useCallback } from 'react';
import './App.css';
import AboutUs from './containers/aboutUs';
import Blog from './containers/blog';
import ContactUs from './containers/contactUs';
import Home from './containers/home';
import MediaCenter from './containers/mediaCenter';
import Products from './containers/products';
import Services from './containers/services';
import useLanguageData from './data';
import { setCurrentSection } from './store/scroll';
import { useDispatch } from 'react-redux';
import { Container } from '@material-ui/core';

function App() {
    const dispatch = useDispatch();

    const data = useLanguageData();
    const sections = data.navbar.sections;

    const scrollfunc = useCallback(() => {
        const navBarHeight = document.getElementById('navbar').scrollHeight;
        const heights = sections.map((a) => document.getElementById(a)?.offsetTop - navBarHeight);
        const currentHeight = window.scrollY;
        const currentSectionId = heights.reverse().findIndex((height) => height <= currentHeight);
        if (currentSectionId !== -1) {
            const currSection = sections[sections.length - currentSectionId - 1];
            dispatch(setCurrentSection(currSection));
        }
    },[sections,dispatch]);
    useEffect(() => {
        window.addEventListener('scroll', scrollfunc);
        return () => {
            window.removeEventListener('scroll', scrollfunc);
        };
    }, []);
    return (
        <>
            <Home />
            <Container>
                <AboutUs />
                <Products />
                <Services />
                <MediaCenter />
                <Blog />
                <ContactUs />
            </Container>
        </>
    );
}

export default App;
