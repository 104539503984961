import { Typography, Box, Grid } from "@material-ui/core";
import useLanguageData from "../../data";
import React from "react";
import CountupCard from "../../components/card/countupCard";


const AboutUs = () => {
    const { html, label, cards } = useLanguageData().aboutUs;
    return (
        <Box id='about-us'>
            <Typography align='center' variant='h4'>
                <b>{label}</b>
            </Typography>
            <Grid container alignContent="center" spacing={4} justify="center">
                <Grid item sm={12} xs={12}>
                    <div className='paraDiv' dangerouslySetInnerHTML={{ __html: html }}></div>
                </Grid>
                {
                    cards.map(({ number, content },i) => (
                        <Grid item xs={12} sm={6} md={3} key={i}>
                            <CountupCard number={number} content={content}></CountupCard>
                        </Grid>
                    ))
                }
            </Grid>
        </Box>
    );
};
export default AboutUs;
