import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { useStyles } from "./style";
import useLanguageData from "../../data";


const Home = ({ classes }) => {

    const { heading, subHeading ,buttonText } = useLanguageData().home;

    return (
        <Box display='flex' id='home' className={classes.root}>
            <Box display='flex' className={classes.container}>
                <Typography variant='h3' align="center">{heading}</Typography>
                <Typography variant='h5' align="center">
                    {subHeading}
                </Typography>
                <Button size="medium" className={classes.button} variant='contained' color='primary'>
                    {buttonText}
                </Button>
            </Box>
        </Box>
    );
};
export default withStyles(useStyles)(Home);
