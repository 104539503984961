import React from 'react'
import { Card, CardContent, Box } from '@material-ui/core'

const countupCard = ({ number, content }) => {
    return (
        <Card elevation={6} style={{height:"250px"}}>
            <Box display="flex"
                justifyContent="center"
                fontSize="5rem"
                color="#782376"
                padding="10px">
                <span>{number}+</span>
            </Box>

            <CardContent>
                <Box display="flex" justifyContent="center" fontSize="1.5rem" color="gray" textAlign="center">

                    {content}
                </Box>
            </CardContent>
        </Card>
    )
}

export default countupCard
