import { createSlice } from "@reduxjs/toolkit";

const scroll = createSlice({
  name: "scroll",
  initialState: {
    height:0,
    currentSection:"home"
  },
  reducers: {
    setCurrentSection: (state, action ) => {
        state.currentSection = action.payload;
    },
    setHeight: (state, action) => {
        state.height = action.payload
    }
  },
});

export const setCurrentSection = scroll.actions.setCurrentSection

export const setHeight = scroll.actions.setHeight

export default scroll.reducer