import React from 'react';
import { Grid, Card } from '@material-ui/core';
import { IconFlagEU } from 'material-ui-flags';
import { withRouter } from 'react-router-dom';
import { US, AE, AU } from 'country-flag-icons/react/3x2';
import FlagRow from './flagRow';

const SimpleCard = ({ history }) => {
    const gotoContactUs = () => {
        history.push('/contact-us');
    };
    return (
        <Card
            color='primary'
            elevation={0}
            style={{ padding: '25px', backgroundColor: 'rgba(0,0,0,0.00)'}}
            onClick={gotoContactUs}>
            <Grid container justify='center' alignContent='center' spacing={4}>
                <FlagRow phoneNo='+61 1300 073 180'>
                    <AU />
                </FlagRow>
                <FlagRow phoneNo='+39 011 8138386'>
                    <IconFlagEU />
                </FlagRow>
                <FlagRow phoneNo='+1 800 334 9399'>
                    <US />
                </FlagRow>
                <FlagRow phoneNo='+971 2 6742587'>
                    <AE />
                </FlagRow>
            </Grid>
        </Card>
    );
};

export default withRouter(SimpleCard);
