import { Card, CardHeader, CardContent, Typography, Divider } from '@material-ui/core';
import React from 'react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CallEndIcon from '@material-ui/icons/CallEnd';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import RingVolumeIcon from '@material-ui/icons/RingVolume';
import MailIcon from '@material-ui/icons/Mail';
import './simpleCard.css';

const SimpleCard = ({ title, address, email, telephones, mobile, hotline }) => {
    const style = {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        fontSize: '0.9rem',
    };

    const openGoogleMaps = () => {
        const formattedAddress = address.split('\n').join(',');
        window.open('http://maps.google.com/maps?daddr=' + formattedAddress, '_blank');
    };
    return (
        <Card style={{ height: '100%', padding: '10px', backgroundColor: 'rgb(255, 255, 255)' }} elevation={5}>
            <CardHeader
                title={title}
                titleTypographyProps={{ variant: 'h6', align: 'center' }}
            />
            <CardContent>
                <LocationOnIcon fontSize='large' style={{ color: '#d54840' }} onClick={openGoogleMaps} />
                <div className='pad-left-15' style={{ display: 'inline-block' }}>
                    {address.split('\n').map((line) => (
                        <Typography style={style}>{line}</Typography>
                    ))}
                </div>
                <Divider variant='middle' />
                <Typography style={style}>
                    <MailIcon style={{ color: '#006dbf' }} fontSize='large' />{' '}
                    <span className='pad-left-15'>{email}</span>
                </Typography>
                <Divider variant='middle' />
                {telephones && (
                    <Typography style={style}>
                        <CallEndIcon style={{ color: 'teal' }} fontSize='large' />{' '}
                        <span className='pad-left-15'>Tel: {telephones.join(', ')}</span>
                    </Typography>
                )}
                <Divider variant='middle' />

                {mobile && (
                    <Typography style={style}>
                        <PhoneAndroidIcon style={{ color: 'blue' }} fontSize='large' />{' '}
                        <span className='pad-left-15'>Mob: {mobile}</span>
                    </Typography>
                )}
                {hotline && (
                    <Typography style={style}>
                        <RingVolumeIcon style={{ color: 'teal' }} fontSize='large' />{' '}
                        <span className='pad-left-15'>Hotline: {hotline}</span>
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

export default SimpleCard;
