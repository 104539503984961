import { Box, Typography } from '@material-ui/core';
import React from 'react';

const MediaCenter = () => {
  return (
    <Box id="media-centre">
      <Typography className="title" align="center" variant="h4">
        <b> Media Centre</b>
      </Typography>
    </Box>
  );
};
export default MediaCenter;
