import React from "react";

import { Card, CardContent, CardHeader, CardMedia, Typography } from "@material-ui/core";

export default function MediaCard({ link, name, description }) {
    return (
        <Card elevation={5} style={{height:'450px'}}>
            <CardMedia style={{height:'300px'}} image={link} alt={name} />
            <CardHeader title={name} titleTypographyProps={{ align: "center", variant: "h5" }} />
            <CardContent>
                <Typography align='justify' variant='body1'>
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
}
