import React from "react";
import { Typography, SvgIcon, Grid, Button } from "@material-ui/core";

const FlagRow = ({ phoneNo, children }) => {
    return (
        <Grid item xs={12} sm={6} md={3} style={{ marginBottom: "25px", display: "flex" }} >
            <Button
                disableFocusRipple
                disableRipple
                disableTouchRipple
                variant='contained'
                style={{ maxWidth: "275px", justifyContent: "left", display: "inherit" }}>
                <Typography style={{ display: "flex", alignItems: "center" }}>
                    <SvgIcon style={{ fontSize: "3em" }}>{children}</SvgIcon>
                    &nbsp;&nbsp;&nbsp;
                    <a style={{ textDecoration: "none" }} href={`tel:${phoneNo.replace(/ /g, "")}`} onClick={e => e.preventDefault()}>
                        <b>{phoneNo}</b>
                    </a>
                </Typography>
            </Button>
        </Grid>
    );
};

export default FlagRow;
