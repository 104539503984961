import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import useLanguageData from '../../data';
import FlatCard from '../../components/card/flatCard';

const ContactUs = ({ history }) => {
    const { label, heading, subHeading } = useLanguageData().contactUs;

    const goToWritetoUs = () => {
        history.push('/write-to-us');
    };

    return (
        <Grid container id='contact-us' alignContent='stretch' spacing={4}>
            <Grid item xs={12}>
                <Typography className='title' align='center' variant='h4'>
                    <b>{label}</b>
                </Typography>
                <Typography align='center' variant='h6'>
                    {heading}
                </Typography>
                <Typography align='center' variant='h6'>
                    {subHeading}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <FlatCard />
            </Grid>
            <Grid
                item
                xs={12}
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    flexDirection: 'column',
                    minHeight: '200px',
                }}>
                <Typography variant='h6'>Want to enquiry about SPIL Glass?</Typography>
                <Button variant='contained' color='secondary' onClick={goToWritetoUs}>
                    Write to Us
                </Button>
            </Grid>
        </Grid>
    );
};

export default withRouter(ContactUs);
