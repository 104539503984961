import { createSlice } from "@reduxjs/toolkit";
import { LANGUAGES } from "../constants";

const slice = createSlice({
  name: "language",
  initialState: {
    language: "English",
  },
  reducers: {
    setLanguage: (state, action) => {
      if (LANGUAGES.indexOf(action.payload) >= 0) {
        state.language = action.payload;
      }
    },
  },
});

export const setLanguage = slice.actions.setLanguage

export default slice.reducer
