import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CssBaseline } from '@material-ui/core';
import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ERPPro from './containers/productDetails/erpPro';
import Opti from './containers/productDetails/opti';
import GlassCloud from './containers/productDetails/glassCloud';
import ContactDetail from './containers/contactDetail';
import AppBar from './components/appbar/appbar';
import { ThemeProvider } from '@material-ui/core';
import { lightTheme } from './themes/themes';
import WriteToUs from './containers/writeToUs';
import Footer from './containers/footer';

const history = createBrowserHistory();

ReactDOM.render(
    <Provider store={store}>
        <CssBaseline />
        <ThemeProvider theme={lightTheme}>
            <Router history={history}>
                <AppBar />
                <Route exact path='/' component={() => <App />} />
                <Route exact path='/products/ERPPro' component={() => <ERPPro />} />
                <Route exact path='/products/Opti' component={() => <Opti />} />
                <Route exact path='/products/GlassCloud' component={() => <GlassCloud />} />
                <Route exact path='/contact-us' component={() => <ContactDetail />} />
                <Route exact path='/write-to-us' component={() => <WriteToUs />} />
                <Footer/>
            </Router>
        </ThemeProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
