import {
  createMuiTheme,
  responsiveFontSizes,
  fade,
} from '@material-ui/core/styles';

export const lightTheme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      type: 'light',
      common: {
        sideBar: '#5d5b6a',
        customerList: '#ECECEC',
        customerInformation: '#f8f8f8',
        customerActivities: fade('#9a9b94', 0.1),
        titleColor: '#F0F0F0',
        callIconGreen: '#45D366',
      },
    },
    typography: {
      fontFamily: [
        'Raleway',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'sans-serif',
      ],
    },
    overrides: {
      MuiButtonBase: {
        root: {
          '&$disabled': {
            border: '0.5px solid gray',
          },
        },
      },
    },
  })
);
