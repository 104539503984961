import { Box, Typography } from "@material-ui/core";
import React from "react";
import useLanguageData from "../../data";

const Services = () => {
    const { html,label } = useLanguageData().services;
    return (
        <Box id='services'>
            <Typography className='title' align='center' variant='h4'>
                <b>{label}</b>
            </Typography>
            <div className='paraDiv' dangerouslySetInnerHTML={{ __html: html }}></div>
        </Box>
    );
};
export default Services;
