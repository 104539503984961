import backgroundImage from "../../resources/images/home.jpg";

export const useStyles = (theme) => ({
    root: {
        minHeight: "75vh",
        background: "url(" + backgroundImage + ")",
        justifyContent: "center"
    },
    container: {
        color: "white",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        lineHeight: 3,
    },
    button: {
        marginTop:'30px',
        fontWeight: "bold",
    },
});
